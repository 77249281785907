/**
 * Convert a normally formatted phone number to a valid tel: link
 */
export default defineNuxtPlugin(() => {
	const phoneRaw = (phoneNumber) => {
		if (!phoneNumber) {
			return '';
		}

		return phoneNumber
			.replace(/^\+/, '00')
			.replace(/\(.*?\)/, '')
			.replace(/[^0-9]/g, '');
	};

	return {
		provide: {
			phoneRaw,
		},
	};
});
