import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contact_45successJtmrpHVWIjMeta } from "/vercel/path0/pages/contact-success.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as highlightsxqIDtGMklEMeta } from "/vercel/path0/pages/highlights.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91item_93VS61Z44hIgMeta } from "/vercel/path0/pages/packages/[item].vue?macro=true";
import { default as index1us1AaJFa5Meta } from "/vercel/path0/pages/packages/index.vue?macro=true";
import { default as reviewsp0OUKSelxuMeta } from "/vercel/path0/pages/reviews.vue?macro=true";
import { default as roomstL5659IyVgMeta } from "/vercel/path0/pages/rooms.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
export default [
  {
    name: "page___en",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page___nl",
    path: "/nl/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page___de",
    path: "/de/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "contact-success___en",
    path: "/contact-success",
    component: () => import("/vercel/path0/pages/contact-success.vue").then(m => m.default || m)
  },
  {
    name: "contact-success___nl",
    path: "/nl/contact-success",
    component: () => import("/vercel/path0/pages/contact-success.vue").then(m => m.default || m)
  },
  {
    name: "contact-success___de",
    path: "/de/contact-success",
    component: () => import("/vercel/path0/pages/contact-success.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___nl",
    path: "/nl/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___de",
    path: "/de/kontakt",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "faq___en",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___nl",
    path: "/nl/veelgestelde-vragen",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___de",
    path: "/de/haufig-gestellte-fragen",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "gallery___en",
    path: "/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "gallery___nl",
    path: "/nl/galerij",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "gallery___de",
    path: "/de/galerie",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "highlights___en",
    path: "/location",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "highlights___nl",
    path: "/nl/locatie",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "highlights___de",
    path: "/de/standort",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "packages-item___en",
    path: "/special-offers/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages-item___nl",
    path: "/nl/arrangementen/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages-item___de",
    path: "/de/arrangements/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages___en",
    path: "/special-offers",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "packages___nl",
    path: "/nl/arrangementen",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "packages___de",
    path: "/de/arrangements",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "reviews___en",
    path: "/reviews",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "reviews___nl",
    path: "/nl/beoordelingen",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "reviews___de",
    path: "/de/rezensionen",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "rooms___en",
    path: "/rooms",
    component: () => import("/vercel/path0/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: "rooms___nl",
    path: "/nl/kamers",
    component: () => import("/vercel/path0/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: "rooms___de",
    path: "/de/zimmer",
    component: () => import("/vercel/path0/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___en",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___nl",
    path: "/nl/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___de",
    path: "/de/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  }
]